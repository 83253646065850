import React, { useState, useEffect } from "react";
import {
  errorMapping,
  makeRequest,
  resetStringStates,
} from "../../../assets/functions_helper";
import {
  Box,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Breadcrumbs,
  Link,
  Switch,
  Snackbar,
  DialogContentText,
  CardActions,
  Button,
} from "@mui/material";
import { CloseButton, SendButton } from "../../../components/buttons";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/customDialog";
import SectionsMenuBuild from "./menu_components/sections_menu_build";
import CreateMenu from "./menu_components/create_menu";
import CreateSection from "./menu_components/create_section";
import AddIcon from "@mui/icons-material/Add";
import menuIcon from "../../../assets/images/icons/menu.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ProductsMenuBuild from "./menu_components/products_menu_build";
import { IconButtonAnimated } from "../../../components/resources";
import ArrowCircleLeft from "../../../assets/images/arrow_circle_left.png";
import ProductSelectionDialog from "./menu_components/product_selection";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { motion } from "framer-motion";
import EditIcon from "@mui/icons-material/Edit";
import { EditMenuStyle } from "./menu_components/menu_styles";

const MenuList = ({
  listOfMenus,
  showMenu,
  menuStates,
  handleSwitchDefaultMenu,
  handleDelete,
  setSelectedMenu,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState(null);

  const handleFavoriteToggle = (menu, isFavorite, event, isActive) => {
    event.stopPropagation();

    if (!menu.is_active && isFavorite) {
      setSnackbarMessage(
        "El menú debe estar activo para configurarse como predeterminado."
      );
      setSnackbarOpen(true);
      return;
    } else if (isActive) {
      setSnackbarMessage("El menú ya se encuentra en estado predeterminado.");
      setSnackbarOpen(true);
      return;
    }

    handleSwitchDefaultMenu(menu.id, isFavorite);
  };

  const handleToggleActivation = async (menuId, isActive, menu) => {
    if (
      "sections" in menu &&
      Array.isArray(menu.sections) &&
      menu.sections.length > 0
    ) {
      const previousActiveState = listOfMenus.find(
        (menu) => menu.id === menuId
      )?.is_active;

      console.log(isActive);

      try {
        handleSwitchDefaultMenu(menuId, isActive, true, isActive);
        await makeRequest(
          "PUT",
          `menu_manager/toggle_menu_activation/${menuId}`,
          String(isActive),
          "text/plain",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType")
        );
      } catch (error) {
        console.error("Error:", error);
        handleSwitchDefaultMenu(
          menuId,
          previousActiveState,
          true,
          previousActiveState
        );
        setSnackbarMessage("Error al actualizar el estado del menú");
        setSnackbarOpen(true);
      }
    } else {
      let errorMessage =
        "El menú debe tener por lo menos una sección y producto.";

      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      return;
    }
  };

  const handleDeleteCloseWindow = (selectedMenuId) => {
    handleDelete(selectedMenuId);
    setDialogOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4}>
        {listOfMenus.map((menu) => (
          <Card
            key={menu.id}
            component={motion.div}
            initial={{ opacity: 0, scale: 0.95, y: 10 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            whileHover={{ scale: 1.05 }}
            sx={{
              width: 270,
              height: 380, // ✅ Standard height
              backgroundColor: "#22223b",
              borderRadius: "18px",
              position: "relative",
              overflow: "hidden",
              boxShadow: menuStates[menu.id]
                ? "0px 8px 20px rgba(90, 78, 153, 0.5)"
                : "0px 4px 10px rgba(0, 0, 0, 0.2)",
              border: menuStates[menu.id]
                ? "2px solid #5A4E99"
                : "2px solid transparent",
              transition: "all 0.3s ease-in-out",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* ✅ Green Active Badge */}
            {menu.is_active && (
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#2ECC71", // ✅ Bright Green for better visibility
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "5px 12px",
                  borderRadius: "12px",
                  zIndex: 2,
                }}
              >
                <CheckCircleIcon sx={{ fontSize: 16, mr: 1 }} />
                ACTIVO
              </Box>
            )}

            {/* 📷 Image Section */}
            <Box
              sx={{
                width: "100%",
                height: "160px",
                overflow: "hidden",
                borderRadius: "14px",
                position: "relative",
              }}
            >
              <CardMedia
                component="img"
                image={menu.image || menuIcon}
                alt={menu.name}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  filter: menuStates[menu.id] ? "none" : "grayscale(30%)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              />
            </Box>

            {/* 📝 Content */}
            <CardContent
              sx={{
                textAlign: "center",
                padding: "12px 10px",
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#c9b7ff",
                  textTransform: "capitalize",
                }}
              >
                {menu.name}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  color: "#a9a9b3",
                  fontSize: "0.85rem",
                  flexGrow: 1, // ✅ Ensures text fills available space
                }}
              >
                {menu.description}
              </Typography>
            </CardContent>

            {/* ⭐ Favorite & 🔄 Activate Switch */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
                mb: 2,
                width: "100%",
              }}
            >
              {/* ⭐ Favorite Button */}
              <Tooltip title="Marcar como predeterminado">
                <IconButton
                  onClick={(e) =>
                    handleFavoriteToggle(
                      menu,
                      !menuStates[menu.id],
                      e,
                      menuStates[menu.id]
                    )
                  }
                  sx={{
                    color: menuStates[menu.id] ? "gold" : "white",
                    transition: "color 0.3s ease-in-out",
                    "&:hover": {
                      color: menuStates[menu.id] ? "darkgoldenrod" : "gold",
                    },
                  }}
                >
                  {menuStates[menu.id] ? (
                    <StarIcon fontSize="large" />
                  ) : (
                    <StarOutlineIcon fontSize="large" />
                  )}
                </IconButton>
              </Tooltip>

              {/* 🔄 Activation Switch */}
              <Tooltip
                title={menu.is_active ? "Desactivar menú" : "Activar menú"}
              >
                <Switch
                  checked={menu.is_active}
                  onChange={(e) =>
                    handleToggleActivation(menu.id, e.target.checked, menu)
                  }
                  color="primary"
                  sx={{
                    "& .MuiSwitch-thumb": {
                      backgroundColor: menu.is_active ? "#2ECC71" : "#ffffff",
                    }, // ✅ Matches new green color
                    "& .MuiSwitch-track": {
                      backgroundColor: menu.is_active ? "#A5D6A7" : "#cccccc",
                    },
                  }}
                />
              </Tooltip>
            </Box>

            {/* 📌 Bottom Actions */}
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: 2,
                width: "100%",
                mt: "auto",
              }}
            >
              {/* 📄 Details Button */}
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button
                  variant="contained"
                  onClick={() => showMenu(menu)}
                  sx={{
                    backgroundColor: "#5A4E99",
                    color: "#fff",
                    fontWeight: "bold",
                    textTransform: "none",
                    borderRadius: "8px",
                    "&:hover": { backgroundColor: "#6B5FB0" },
                  }}
                >
                  Detalles
                </Button>
              </motion.div>

              {/* 🗑️ Delete Button */}
              <Tooltip title="Eliminar menú">
                <IconButton
                  onClick={() => {
                    setSelectedMenuId(menu.id)
                    setDialogOpen(true)
                  }}
                  sx={{
                    color: "error.main",
                    "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.1)" },
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              </Tooltip>

              {/* ✏️ Edit Styles Button */}
              <Tooltip title="Editar estilos">
                <IconButton
                  onClick={() => {
                    setSelectedMenu(menu);
                  }}
                  sx={{
                    color: "primary.main",
                    "&:hover": { backgroundColor: "rgba(0, 100, 255, 0.1)" },
                  }}
                >
                  <EditIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        ))}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />

      <CustomDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CustomDialogTitle id="alert-dialog-title">
          Confirmar eliminación
        </CustomDialogTitle>
        <CustomDialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de querer eliminar el menú? Esta
            acción no se puede deshacer.
          </DialogContentText>
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton onClick={() => setDialogOpen(false)} color="primary">
            Cancelar
          </CloseButton>
          <SendButton
            onClick={() => handleDeleteCloseWindow(selectedMenuId)}
            color="error"
            variant="contained"
            autoFocus
          >
            Eliminar
          </SendButton>
        </CustomDialogActions>
      </CustomDialog>
    </>
  );
};


const ButtonBack = ({ page, hasChanges, onBackConfirm }) => (
  <IconButtonAnimated
    size="small"
    aria-label="close"
    color="inherit"
    onClick={() => {
      if (hasChanges) {
        onBackConfirm();
      } else {
        page(false);
      }
    }}
  >
    <img
      src={ArrowCircleLeft}
      alt="Notification"
      style={{ width: "30px", height: "30px" }}
    />
  </IconButtonAnimated>
);

function MenuRestaurant({ restaurantId }) {
  // Estado general del componente
  const [listOfMenus, setListOfMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMenuDialog, setOpenMenuDialog] = useState(false);
  const [openSectionDialog, setOpenSectionDialog] = useState(false);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  // Estados relacionados con la creación de menús y secciones
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameSection, setNameSection] = useState("");

  const [sectionMenu, setSectionMenu] = useState(false);
  const [sectionProducts, setSectionProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingSectionMenu, setLoadingSectionMenu] = useState(false);
  const [sections, setSections] = useState([]);
  const [menuId, setMenuId] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [menuInfoComplete, setMenuInfoComplete] = useState(false);
  const [section, setSection] = useState({});

  const [nameOfMenu, setNameOfMenu] = useState("");
  const [itemsWithCorrectOrderValue, setItemsWithCorrectOrderValue] = useState(
    []
  );
  const [menuStates, setMenuStates] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedMenu, setSelectedMenu] = useState(null);

  useEffect(() => {
    makeRequest(
      "GET",
      "menu_manager/view",
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setListOfMenus(response);
        if (response) {
          const menuDefault = {};
          response.forEach((menu) => {
            if (menu.is_default === true) {
              menuDefault["menu"] = menu;
            }
          });
          if (menuDefault) {
            setMenuStates({ [menuDefault.menu.id]: true });
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log("Error al obtener los menús:", error);
        setLoading(false);
      });
  }, []);

  const createMenu = () => {
    setLoading(true);
    makeRequest(
      "POST",
      "menu_manager/create_menu",
      { restaurant_id: restaurantId, name, description },
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        const newMenu = {
          ...response,
          is_active: false,
          is_default: false,
        };
        setListOfMenus([...listOfMenus, newMenu]);
        setOpenMenuDialog(false);
        setLoading(false);
        handleClearAllMenus();
      })
      .catch((error) => {
        for (const err in errorMapping) {
          if (err.includes(error["error_code"])) {
            setSnackbarMessage(errorMapping[err]);
            setSnackbarOpen(true);
            setLoading(false);
            return;
          }
        }
      });
  };

  const showMenu = (menu) => {
    setLoadingSectionMenu(true);
    makeRequest(
      "GET",
      `menu_manager/view/${menu.id}`,
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log(response);
        setMenuInfoComplete(response);
        setSections(response.sections || []);
        setMenuId(menu.id);
        setSectionMenu(true);
        setLoadingSectionMenu(false);
        setNameOfMenu(response.name);
      })
      .catch((error) => {
        console.log("Error al obtener las secciones del menú:", error);
        setLoadingSectionMenu(false);
      });
  };

  const createSection = (name) => {
    console.log(menuInfoComplete);
    console.log(menuId);
    const sectionCreate = [
      ...sections,
      { name, order: sections.length + 1, products: [] },
    ];
    const menuCompleted = {
      sections: sectionCreate,
      is_active: menuInfoComplete.is_active,
      is_default: menuInfoComplete.is_default,
      restaurant_id: restaurantId,
      menu_id: menuId,
      schedule: menuInfoComplete.schedule,
      menu_type: "default"
    };
    console.log(menuCompleted);
    setMenuInfoComplete(menuCompleted);
    makeRequest(
      "POST",
      "menu_manager/edit_menu",
      menuCompleted,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setSections(sectionCreate);

        setOpenSectionDialog(false);
      })
      .catch((error) => {
        console.log("Error al crear la sección:", error);
      });
  };

  const showProducts = (section) => {
    console.log("Mostrar productos de la sección:", section);

    if ("products" in section) {
      setProducts(section.products);
    } else {
      setProducts([]);
    }
    setSection(section);
    setSectionProducts(true);
  };

  const addProduct = (selectedProduct) => {
    const newProduct = {
      description: selectedProduct.description || "Descripción no disponible",
      product_id: selectedProduct.id,
      image_url: selectedProduct.image_url,
      price: selectedProduct.price || 0,
      order: products.length,
      pos_id: selectedProduct.pos_id || "sin_pos_id",
      name: selectedProduct.name || "Producto sin nombre",
    };

    const productsUpdated = [...products, newProduct];

    setProducts(productsUpdated);
    setHasChanges(true);
    setItemsWithCorrectOrderValue(productsUpdated);
    console.log(productsUpdated);
  };

  const saveChanges = (isSections) => {
    let updatedMenuInfo = {};
    let updatedSections = [];
    if (isSections) {
      updatedSections = itemsWithCorrectOrderValue;
    } else {
      console.log(menuInfoComplete);
      updatedSections = menuInfoComplete.sections.map((sec) => {
        if (sec.order === section.order) {
          return {
            ...sec,
            products: itemsWithCorrectOrderValue,
          };
        }
        return sec;
      });
    }

    updatedMenuInfo = {
      ...menuInfoComplete,
      sections: updatedSections,
      menu_id: menuInfoComplete.id
        ? menuInfoComplete.id
        : menuInfoComplete.menu_id,
      schedule: menuInfoComplete.schedule,
      menu_type: "default"
    };

    console.log(updatedMenuInfo);

    makeRequest(
      "POST",
      "menu_manager/edit_menu",
      updatedMenuInfo,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log("Sección guardada exitosamente:", response);
        setSections(updatedSections);
        setHasChanges(false);
      })
      .catch((error) => {
        console.error("Error al guardar la sección:", error);
      });
  };

  const handleBackButton = () => {
    if (hasChanges) {
      setOpenWarningDialog(true);
    } else {
      setSectionProducts(false);
    }
  };

  const handleWarningClose = (save) => {
    setOpenWarningDialog(false);

    if (save) {
      saveChanges();
    } else {
      if (sectionProducts === true && sectionMenu === true) {
        setSectionProducts(false);
        setHasChanges(false);
      } else {
        setSectionMenu(false);
        setHasChanges(false);
      }
    }
  };

  const handleSwitchDefaultMenu = async (
    menuId,
    newState,
    isActivate,
    state
  ) => {
    const previousMenus = [...listOfMenus]; // Copia del estado original
    const previousState = menuStates;
    console.log(state);
    try {
      if (isActivate) {
        // Actualización optimista del estado local
        const updatedMenus = listOfMenus.map((menu) =>
          menu.id === menuId ? { ...menu, is_active: state } : menu
        );

        // Actualiza listOfMenus con el nuevo estado
        setListOfMenus(updatedMenus);
      } else {
        // Lógica original para el menú predeterminado
        makeRequest(
          "POST",
          `menu_manager/update_default?menu_id=${menuId}`,
          {},
          "application/json",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType")
        )
          .then(() => {
            if (newState) setMenuStates({ [menuId]: true });
          })
          .catch(() => {
            setMenuStates(previousState);
          });
      }
    } catch (error) {
      console.error("Error:", error);
      // Revertir cambios en caso de error
      if (isActivate) {
        setListOfMenus(previousMenus);
      } else {
        setMenuStates(previousState);
      }
    }
  };

  const handleDelete = async (selectedMenuId) => {
    try {
      await makeRequest(
        "DELETE",
        "menu_manager/delete_menu",
        [selectedMenuId.toString()],
        "application/json",
        localStorage.getItem("token"),
        localStorage.getItem("tokenType")
      );

      const updatedMenus = listOfMenus.filter(
        (menu) => menu.id !== selectedMenuId
      );
      setListOfMenus(updatedMenus);
    } catch (error) {
      console.error("Error eliminando menú:", error);
    }
  };

  const handleClearAllMenus = () => {
    resetStringStates(setName, setDescription);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <>
      <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 2 }}>
          <Link
            underline="hover"
            color="inherit"
            href="#"
            onClick={() => {
              if (hasChanges) {
                handleBackButton();
              } else {
                setSectionMenu(false);
                setSectionProducts(false);
              }
            }}
          >
            Menús
          </Link>
          {sectionMenu && (
            <Link
              underline="hover"
              color="inherit"
              href="#"
              onClick={() => {
                if (hasChanges) {
                  handleBackButton();
                } else {
                  setSectionMenu(true);
                  setSectionProducts(false);
                }
              }}
            >
              Secciones de {nameOfMenu}
            </Link>
          )}
          {sectionProducts && (
            <Typography color="textPrimary">Productos</Typography>
          )}
        </Breadcrumbs>
      </>
      {sectionProducts ? ( //PRODUCTS LIST
        <>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              padding: "20px",
            }}
          >
            <ButtonBack
              page={setSectionProducts}
              hasChanges={hasChanges}
              onBackConfirm={handleBackButton}
            />
            <SendButton
              onClick={() => setOpenProductDialog(true)}
              sx={{ maxWidth: "200px" }}
            >
              Agregar Producto
            </SendButton>
            {hasChanges && ( // Condicional para mostrar el botón de guardar
              <SendButton
                onClick={() => saveChanges(false)}
                sx={{ maxWidth: "200px" }}
              >
                Guardar Cambios
              </SendButton>
            )}
          </Box>
          <ProductsMenuBuild
            items={products}
            onClickProducts={() => {}}
            setHasChanges={setHasChanges}
            setItemsWithCorrectOrderValue={setItemsWithCorrectOrderValue}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 4,
              width: "100%",
            }}
          >
            <IconButton
              onClick={() => setOpenProductDialog(true)}
              sx={{
                clipPath: "circle(50% at 50% 50%)",
                background: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          {/* Agregar nuevo producto a la seccion*/}
          <ProductSelectionDialog
            open={openProductDialog}
            handleClose={() => setOpenProductDialog(false)}
            restaurantId={restaurantId}
            onAddProduct={addProduct}
          />
        </>
      ) : sectionMenu ? ( //SECTIONS LIST
        <>
          {loadingSectionMenu ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  padding: "20px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <RestaurantMenuIcon sx={{ color: "white", fontSize: 28 }} />{" "}
                  {/* Icono al inicio */}
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: 24,
                      fontWeight: "400",
                      wordWrap: "break-word",
                      width: "70%",
                      "@media (max-width:950px)": {
                        width: "100%",
                        textAlign: "center",
                      },
                    }}
                  >
                    Secciones del menú {nameOfMenu}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  padding: "20px",
                }}
              >
                <ButtonBack
                  page={setSectionMenu}
                  hasChanges={hasChanges}
                  onBackConfirm={handleBackButton}
                />
                <SendButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{ maxWidth: "200px" }}
                >
                  Crear Nueva Sección
                </SendButton>
                {hasChanges && (
                  <SendButton
                    onClick={() => saveChanges(true)}
                    sx={{ maxWidth: "200px" }}
                  >
                    Guardar Cambios
                  </SendButton>
                )}
              </Box>
              <SectionsMenuBuild
                items={sections}
                onClickProducts={(id) => {
                  showProducts(id);
                }}
                setHasChanges={setHasChanges}
                setItemsWithCorrectOrderValue={setItemsWithCorrectOrderValue}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 4,
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{
                    clipPath: "circle(50% at 50% 50%)",
                    background:
                      "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </>
          )}
        </>
      ) : (
        //MENUS LIST
        <>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : selectedMenu !== null ? (
            <EditMenuStyle
              menu={selectedMenu}
              onBack={() => {
                setSelectedMenu(null);
              }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                padding: "20px",
                flexDirection: "column",
              }}
            >
              <SendButton
                onClick={() => setOpenMenuDialog(true)}
                sx={{ maxWidth: "200px", marginBottom: "20px" }}
              >
                Crear Nuevo Menú
              </SendButton>
              <MenuList
                listOfMenus={listOfMenus}
                showMenu={showMenu}
                menuStates={menuStates}
                handleSwitchDefaultMenu={handleSwitchDefaultMenu}
                handleDelete={handleDelete}
                setSelectedMenu={setSelectedMenu}
              />
            </Box>
          )}
        </>
      )}

      {/* Dialogo para crear un nuevo menú */}
      <CustomDialog
        open={openMenuDialog}
        onClose={() => setOpenMenuDialog(false)}
      >
        <CustomDialogTitle>Crear Menú</CustomDialogTitle>
        <CustomDialogContent>
          <CreateMenu
            name={name}
            description={description}
            setName={setName}
            setDescription={setDescription}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton
            onClick={() => {
              setOpenMenuDialog(false);
              handleClearAllMenus();
            }}
          >
            Cerrar
          </CloseButton>
          <SendButton onClick={createMenu}>Crear</SendButton>
        </CustomDialogActions>
      </CustomDialog>

      {/* Dialogo para crear una nueva sección */}
      <CustomDialog
        open={openSectionDialog}
        onClose={() => setOpenSectionDialog(false)}
      >
        <CustomDialogTitle>Crear Nueva Sección</CustomDialogTitle>
        <CustomDialogContent>
          <CreateSection
            nameSection={nameSection}
            setNameSection={setNameSection}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton onClick={() => setOpenSectionDialog(false)}>
            Cerrar
          </CloseButton>
          <SendButton onClick={() => createSection(nameSection)}>
            Crear
          </SendButton>
        </CustomDialogActions>
      </CustomDialog>
      {/* Diálogo de advertencia cuando hay cambios no guardados */}
      <CustomDialog
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}
      >
        <CustomDialogTitle>Advertencia</CustomDialogTitle>
        <CustomDialogContent>
          <Typography variant="body1">
            Tienes cambios no guardados. ¿Deseas guardar antes de volver?
          </Typography>
        </CustomDialogContent>
        <CustomDialogActions>
          <SendButton onClick={() => handleWarningClose(true)}>
            Guardar
          </SendButton>
          <CloseButton onClick={() => handleWarningClose(false)}>
            No guardar
          </CloseButton>
        </CustomDialogActions>
      </CustomDialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
}

export default MenuRestaurant;
