import axios from "axios";
import qs from "qs"; // Importa la librería qs
import { useNavigate } from "react-router-dom";
const url = "https://eatery-z81ocn81.b4a.run/"; // URL of the backend production server
//const url = 'http://localhost:3000/'; // URL of the backend development server

export const errorMapping = {
  "0001": "La contraseña no puede ser la misma que la antigua.",
  "0002": "El correo electrónico ya está registrado.",
  "0003": "El nombre del restaurante ya ha sido registrado.",
  "0004": "El nombre de la mesa ya ha sido registrado.",
  "0005": "antes de volver a llamar al mesero.",
  "0007": "Alcansaste el numero maximo de menus para este restaurante.",
};

export function createMakeRequest(navigate) {
  return async function makeRequest(
    method,
    endpoint,
    body = null,
    contentType = "application/json",
    token = null,
    tokenType = null,
    onTokenExpired = null
  ) {
    let data = body;

    if (contentType === "application/x-www-form-urlencoded") {
      data = qs.stringify(body); // Convierte el objeto body a una cadena URL-encoded
    }

    // Configuración inicial
    let config = {
      method: method,
      url: url + endpoint,
      data: data,
      headers: {
        "Content-Type": contentType,
      },
    };

    // Añadir token a la configuración si está disponible
    if (token && tokenType) {
      config.headers.Authorization = `${tokenType} ${token}`;
    }

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      // Manejar error de token expirado (401 Unauthorized)
      if (error.response && error.response.status === 401) {
        try {
          // Intentar refrescar el token
          const refreshToken = await axios.post(url + "users/refresh_token", {
            token: localStorage.getItem("refreshToken") || localStorage.getItem("token"),
          });

          if (refreshToken.status === 200) {
            // Guardar nuevo token
            localStorage.setItem("token", refreshToken.data.access_token);
            localStorage.setItem("tokenType", refreshToken.data.token_type);
            
            // Reintentar la solicitud original con el nuevo token
            config.headers.Authorization = `${refreshToken.data.token_type} ${refreshToken.data.access_token}`;
            const newResponse = await axios(config);
            return newResponse.data;
          }
        } catch (refreshError) {
          // Si no se puede refrescar el token, limpiar localStorage y redirigir
          localStorage.removeItem("token");
          localStorage.removeItem("tokenType");
          localStorage.removeItem("refreshToken");
          
          // Usar la función de callback si se proporciona
          if (typeof onTokenExpired === 'function') {
            onTokenExpired();
          } 
          // Redirigir al login usando navigate
          else if (navigate) {
            navigate('/login', { state: { from: window.location.pathname, message: "Tu sesión ha expirado. Por favor inicia sesión nuevamente." } });
          }
          
          throw new Error("Sesión expirada");
        }
      }
      
      // Para otros errores, lanzarlos para manejarlos en el componente
      console.error("Error en la petición:", error);
      if (error.response && error.response.data && error.response.data.detail) {
        throw error.response.data.detail;
      } else {
        throw new Error(error.message || "Error en la solicitud");
      }
    }
  };
}

export function useMakeRequest() {
  const navigate = useNavigate();
  return createMakeRequest(navigate);
}

export async function makeRequest(
  method,
  endpoint,
  body = null,
  contentType = "application/json",
  token = null,
  tokenType = null,
  onTokenExpired = null
) {
  return createMakeRequest(null)(
    method, 
    endpoint, 
    body, 
    contentType, 
    token, 
    tokenType, 
    onTokenExpired || (() => window.location.href = '/login')
  );
}

export const resetStringStates = (...setters) => {
  setters.forEach((setter) => setter(""));
};
