import ListData from "../../../components/listData";
import { useEffect } from "react";
import { makeRequest } from "../../../assets/functions_helper";
import { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  DialogContentText,
  Snackbar,
} from "@mui/material";
import CreateUser from "./create_user";
import { IconButtonAnimated } from "../../../components/resources";
import CloseIcon from "@mui/icons-material/Close";
import DetailUser from "./detail_user";
import { useNavigate } from "react-router-dom";
import { errorMapping } from "../../../assets/functions_helper";
import { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "../../../components/customDialog";
import { CloseButton, SendButton } from "../../../components/buttons";

function ListUsers() {
  const [users, setUsers] = useState([]); // Variable de estado para guardar los usuarios
  const [total, setTotal] = useState(0); // Variable de estado para guardar el total de usuarios
  const [pages, setPages] = useState(0); // Variable de estado para guardar el total de paginas
  const [currentPage, setCurrentPage] = useState(0); // Variable de estado para guardar la pagina actual
  const [isLoaded, setIsLoaded] = useState(false); // Variable de estado para saber si la petición ha cargado
  const [page, setPage] = useState(1); // Variable de estado para guardar la pagina actual

  const [open, setOpen] = useState(false); // Variable de estado para abrir y cerrar el dialogo

  const [name, setName] = useState(""); // Variables de estado para guardar los valores de los inputs
  const [email, setEmail] = useState(""); // Variables de estado para guardar los valores de los inputs
  const [lastName, setLastName] = useState(""); // Variables de estado para guardar los valores de los inputs
  const [password, setPassword] = useState(""); // Variables de estado para guardar los valores de los inputs

  const [messageSnackBar, setMessageSnackBar] = useState(""); // Variable de estado para guardar el mensaje del snackbar

  const [openSnackBar, setOpenSnackBar] = useState(false); // Variable de estado para abrir y cerrar el snackbar

  const [openEdit, setOpenEdit] = useState(false); // Variable de estado para abrir y cerrar el dialogo de editar

  const [userId, setUserId] = useState(""); // Variable de estado para guardar el id del usuario

  const [userDetail, setUserDetail] = useState({
    name: "",
    last_name: "",
    email: "",
    lastLogin: "",
    is_active: true,
    roles: [],
  });

  const [rolesDetail, setRolesDetail] = useState([]);

  const [selectedRoles, setSelectedRoles] = useState([]);

  const navigate = useNavigate();

  const [rolesFilter, setRolesFilter] = useState(""); // Variable de estado para guardar los roles
  const [roles, setRoles] = useState([]); // Variable de estado para guardar los roles

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    setName("");
    setEmail("");
    setLastName("");
    setPassword("");
  };

  useEffect(() => {
    let url = ``;

    if (rolesFilter !== "") {
      console.log("hola2");
      url = `users/?page=${page}&size=5&order_by=id&order=desc&role_ids=${rolesFilter}`;
    } else {
      console.log("hola");
      url = `users/?page=${page}&size=5&order_by=id&order=desc${rolesFilter}`;
    }

    makeRequest(
      "GET",
      url,
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    )
      .then((response) => {
        console.log(response);
        const updatedUsers = response.users.map((user) => ({
          ...user,
          title: user.email,
          title2: `${user.name} ${user.last_name}`,
        }));
        setUsers(updatedUsers);
        setTotal(response.total);
        setPages(response.pages);
        setCurrentPage(response.currentPage);

        makeRequest(
          "GET",
          "users/roles",
          null,
          "application/json",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType"),
          null,
          null,
          () => {
            navigate("/login");
          }
        )
          .then((response) => {
            const updatedResponse = response.map((item) => ({
              ...item,
              title: item.name_es,
            }));

            setRoles(updatedResponse);

            setIsLoaded(true);
          })
          .catch((error) => {
            setSnackbarMessage(`Ocurrio un error al obtener los roles`);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });
      })
      .catch((error) => {
        setSnackbarMessage(`Ocurrio un error al obtener los usuarios`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  }, [navigate, rolesFilter, page]);

  const handleSubmitCreateUser = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || lastName === "" || password === "") {
      setMessageSnackBar("Campos vacios");
      setOpenSnackBar(true);
      return;
    } else {
      makeRequest(
        "POST",
        "users/create_user/",
        {
          name: name,
          email: email,
          last_name: lastName,
          password: password,
        },
        "application/json",
        localStorage.getItem("token"),
        localStorage.getItem("tokenType"),
        null,
        null,
        () => {
          navigate("/login");
        }
      )
        .then((response) => {
          setMessageSnackBar("Usuario creado correctamente");
          setOpenSnackBar(true);
        })
        .catch((err) => {
          console.log(err["error_code"]);
          for (const error in errorMapping) {
            console.log(err["error_code"]);
            if (error.includes(err["error_code"])) {
              setMessageSnackBar(errorMapping[err["error_code"]]);
              setOpenSnackBar(true);
              return; // Salir de la función si se encuentra un código de error
            }
          }
        });
    }
  };

  const handleSubmitEditUser = (e) => {
    e.preventDefault();

    if (
      userDetail.name === "" ||
      userDetail.email === "" ||
      userDetail.last_name === ""
    ) {
      setMessageSnackBar("Campos vacios");
      setOpenSnackBar(true);
      return;
    } else {
      console.log(selectedRoles);
      const updatedRol = selectedRoles.map((rol) => (rol.id ? rol.id : 0));
      console.log(updatedRol);
      makeRequest(
        "PUT",
        `users/${userId}`,
        {
          name: userDetail.name,
          email: userDetail.email,
          last_name: userDetail.last_name,
          is_active: userDetail.is_active,
          roles: updatedRol,
        },
        "application/json",
        localStorage.getItem("token"),
        localStorage.getItem("tokenType"),
        null,
        null,
        () => {
          navigate("/login");
        }
      )
        .then((response) => {
          setMessageSnackBar("Usuario actualizado correctamente");
          setOpenSnackBar(true);
        })
        .catch((error) => {
          if (error.length > 0) {
            setOpenSnackBar(true);
            setMessageSnackBar(error[0].msg);
          } else {
            console.log(error);
            setOpenSnackBar(true);
            setMessageSnackBar("Error al actualizar el usuario");
          }
        });
    }
  };

  const action = (
    <>
      <IconButtonAnimated
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setOpenSnackBar(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButtonAnimated>
    </>
  );

  return (
    <>
      {isLoaded ? (
        <>
          <ListData
            title="Usuarios"
            data={users}
            setData={setUsers}
            pages={pages}
            total={total}
            edit={true}
            editMessage="Editar Usuario"
            editFunction={(userId) => {
              setUserId(userId);
              setOpenEdit(true);
            }}
            currentPage={currentPage}
            page={page}
            setPage={setPage}
            changePage={() => {}}
            create={true}
            createMessage="Crear Usuario"
            createFunction={() => {
              handleClickOpen();
            }}
            nameColumn1="Correo"
            nameColumn2="Nombre"
            nameColumn3="Acciones"
            filterSelect={true}
            filterSelectFunction={(value) => {
              console.log(value);
              setPage(1);
              setRolesFilter(value);
            }}
            valuesFilter={roles}
            valueFilter={rolesFilter}
          />

          <CustomDialog open={open} onClose={handleClose}>
            <CustomDialogTitle>Crear Usuario</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText sx={{
                textAlign: "center",
              }}>
                Diligencia todos los campos para crear un nuevo usuario
              </DialogContentText>
              <CreateUser
                name={name}
                lastName={lastName}
                email={email}
                password={password}
                setName={setName}
                setLastName={setLastName}
                setEmail={setEmail}
                setPassword={setPassword}
              />
            </CustomDialogContent>
            <CustomDialogActions>
              <CloseButton onClick={handleClose}>Cerrar</CloseButton>
              <SendButton onClick={handleSubmitCreateUser}>Enviar</SendButton>
            </CustomDialogActions>
          </CustomDialog>

          <CustomDialog open={openEdit} onClose={() => setOpenEdit(false)}>
            <CustomDialogTitle>Editar Usuario</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText sx={{
                textAlign: "center",
              }}>
                Diligencia los campos que desea actualizar
              </DialogContentText>
              <DetailUser
                userId={userId}
                user={userDetail}
                setUser={setUserDetail}
                roles={rolesDetail}
                setRoles={setRolesDetail}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
              />
            </CustomDialogContent>
            <CustomDialogActions>
              <CloseButton onClick={() => setOpenEdit(false)}>Cerrar</CloseButton>
              <SendButton onClick={handleSubmitEditUser}>Enviar</SendButton>
            </CustomDialogActions>
          </CustomDialog>

          <Snackbar
            open={openSnackBar}
            autoHideDuration={2000}
            handleClose={() => {
              setOpenSnackBar(false);
            }}
            message={messageSnackBar}
            action={action}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ListUsers;
