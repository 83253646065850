import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CodeInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'center',
  margin: theme.spacing(2, 0),
}));

const CodeInput = styled(TextField)(({ theme }) => ({
  width: '56px',
  '& .MuiInputBase-input': {
    height: '56px',
    padding: 0,
    fontSize: '1.5rem',
    textAlign: 'center',
  },
}));

const VerificationCode = ({ length = 5 }) => {
  const { register, setValue, formState: { errors } } = useFormContext();
  const [code, setCode] = useState(Array(length).fill(''));
  
  const inputRefs = Array(length).fill(0).map(() => React.createRef());

  const handleChange = (index, e) => {
    const value = e.target.value;
    
    if (!/^\d*$/.test(value)) return;
    
    const newCode = [...code];
    newCode[index] = value.slice(-1);
    setCode(newCode);
    
    setValue('verificationCode', newCode.join(''), { shouldValidate: true });
    
    if (value && index < length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };


  const handleKeyDown = (index, e) => {

    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (e.key === 'ArrowRight' && index < length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };


  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').trim();
    
    if (!/^\d+$/.test(pastedData)) return;
    
    const digits = pastedData.slice(0, length).split('');
    const newCode = [...code];
    
    digits.forEach((digit, index) => {
      if (index < length) {
        newCode[index] = digit;
        if (inputRefs[index].current) {
          inputRefs[index].current.value = digit;
        }
      }
    });
    
    setCode(newCode);
    setValue('verificationCode', newCode.join(''), { shouldValidate: true });
    
    const nextEmptyIndex = digits.length < length ? digits.length : length - 1;
    if (inputRefs[nextEmptyIndex]) {
      inputRefs[nextEmptyIndex].current.focus();
    }
  };

  const { ref: hiddenInputRef, ...hiddenInputProps } = register('verificationCode', {
    required: "El codigo es requerido",
    minLength: {
      value: length,
      message: `Porfavor coloca todos los digitos`
    },
    pattern: {
      value: /^\d+$/,
      message: "Solo se aceptan numeros"
    }
  });

  return (
    <Box>
      <CodeInputContainer>
        {Array(length).fill(0).map((_, index) => (
          <CodeInput
            key={index}
            inputRef={inputRefs[index]}
            variant="outlined"
            inputProps={{
              maxLength: 1,
              'aria-label': `Digit ${index + 1}`,
              autoComplete: 'off',
            }}
            value={code[index]}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={index === 0 ? handlePaste : undefined}
            error={!!errors?.verificationCode}
          />
        ))}
      </CodeInputContainer>
      
      {errors?.verificationCode && (
        <Typography color="error" variant="caption" sx={{ display: 'block', textAlign: 'center' }}>
          {errors.verificationCode.message}
        </Typography>
      )}
      
      <input
        type="hidden"
        ref={hiddenInputRef}
        {...hiddenInputProps}
      />
    </Box>
  );
};

export default VerificationCode;