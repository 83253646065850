import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
//import Login from "./pages/login"; // Import the Login component
//import CreateUser from './components/create_user'; // Import the Create_user component
//import DetailUser from './pages/modules/admin_users/detail_user'; // Import the Detail_user component
//import ListUsers from "./pages/modules/admin_users/list_users"; // Import the List_users component
import App from "./App"; // Import the App component
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { GoogleOAuthProvider } from "@react-oauth/google";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#020213", // Fondo para toda la aplicación
      paper: "rgba(255, 255, 255, 0.05)", // Fondo para componentes como el Dialog
    },
    text: {
      primary: "#ffffff", // Texto blanco para que contraste con el fondo oscuro
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#020213",
        },
      },
    },
  },
});

const clientId = "279617664043-rhik77roo52kob1tfe1j80da82m41pqe.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId={clientId}>
        <App />
      </GoogleOAuthProvider>
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
