import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
import CompletationInfo from "./completationInfo";
import { NoSuggestionsTextField } from "../../../components/textFields";
import { makeRequest } from "../../../assets/functions_helper";
import LogoLogo from "../../../assets/images/logo-calls.png";
import TextLogo from "../../../assets/images/text-logo-calls.png";
import { SendButton } from "../../../components/buttons";

const UserForm = () => {
  const methods = useForm();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const onSubmit = (data) => {
    console.log("Form Data: ", data);
    makeRequest(
      "POST",
      `restaurant_admin/register_user/?country_id=${data.countryId}`,
      {
        name: data.name,
        email: data.email,
        last_name: data.last_name,
        cellphone_number: data.phoneNumber,
      },
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setSnackbarMessage(`Ocurrio un error al completar la informacion`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
    methods.reset();
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              marginTop: 4,
              borderRadius: "20px",
              boxShadow: "0px 0px 10px 0px #4EACDA",
              backgroundColor: "#070724",
              backdropFilter: "blur(5px)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <img
                src={LogoLogo}
                alt="Logo"
                style={{ width: "75.79", height: "72.39" }}
              />
              <img
                src={TextLogo}
                alt="Logo"
                style={{ width: "75.79", height: "72.39" }}
              />
            </Box>

            <Typography variant="h5" align="center" gutterBottom>
              Digilencia la siguiente informacion
            </Typography>

            {/* Wrap form with FormProvider */}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <NoSuggestionsTextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      {...methods.register("name", {
                        required: "Nombre es requerido",
                      })}
                      error={!!methods.formState.errors.name}
                      helperText={methods.formState.errors.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NoSuggestionsTextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      type="email"
                      {...methods.register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Correo invalido.",
                        },
                      })}
                      error={!!methods.formState.errors.email}
                      helperText={methods.formState.errors.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NoSuggestionsTextField
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      {...methods.register("last_name", {
                        required: "El apellido es requerido.",
                      })}
                      error={!!methods.formState.errors.last_name}
                      helperText={methods.formState.errors.last_name?.message}
                    />
                  </Grid>

                  {/* ✅ Integrate CompletationInfo Component */}
                  <Grid item xs={12}>
                    <CompletationInfo />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <SendButton
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Enviar
                      </SendButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Paper>
        </Box>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserForm;
