import React from "react";
import { Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { CustomSelect, NoSuggestionsTextField } from "../../../components/textFields";

function InsertsUsersToRestaurant({ user = {}, setUser, waiters = false }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleChangeSelects = (e, isArray = false) => {
    const { name, value } = e.target;
    if (isArray) {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: [parseInt(value)],
      }));
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  return (
    <Box>
      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="name"
        label="Nombre"
        type="text"
        fullWidth
        value={user.name}
        onChange={handleChange}
      />
      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="last_name"
        name="last_name"
        label="Apellido"
        type="text"
        fullWidth
        value={user.last_name}
        onChange={handleChange}
      />
      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="email"
        name="email"
        label="Correo"
        type="email"
        fullWidth
        value={user.email}
        onChange={handleChange}
      />
      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="password"
        name="password"
        label="Contraseña"
        type="password"
        fullWidth
        value={user.password}
        onChange={handleChange}
      />

      {waiters && (
        <FormControl
          fullWidth
          sx={{
            marginTop: 2,
          }}
        >
          <InputLabel id="demo-simple-select-label">Rol</InputLabel>
          <CustomSelect
            labelId="demo-simple-select-label"
            id="select"
            name="roles"
            value={user.roles[0] ? user.roles[0] : ""}
            label="rol"
            onChange={(e) => handleChangeSelects(e, true)}
          >
            <MenuItem value={3}>Mesero</MenuItem>
            <MenuItem value={2}>Mesero Lider</MenuItem>
          </CustomSelect>
        </FormControl>
      )}
    </Box>
  );
}

export default InsertsUsersToRestaurant;
