import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { motion } from "framer-motion";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import imagenLocal from "../../../../assets/images/sections.jpg";

export const OrderProductCard = ({ product, onSelect, customStyles }) => {
  return (
    <Card
      component={motion.div}
      initial={{ opacity: 0, scale: 0.95, y: 10 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      whileHover={{ scale: 1.03 }}
      sx={{
        maxWidth: 380,
        backgroundColor: customStyles?.product_background_color || "#1f1f2e", // Dark theme
        borderRadius: "20px", // Smooth edges
        boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.4)", // Strong shadow
        overflow: "hidden",
        display: "flex",
        flexDirection: "row", // 🔥 Horizontal Layout
        height: "160px", // ⬇️ Shorter but wider card
        position: "relative",
        alignItems: "center",
      }}
    >
      {/* 📷 Product Image on the Left */}
      <Box sx={{ position: "relative", width: "40%", height: "100%" }}>
        <CardMedia
          component="img"
          alt={product.name}
          image={product.image_url ? product.image_url : imagenLocal}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: "brightness(90%)",
            transition: "transform 0.3s ease",
            "&:hover": { transform: "scale(1.05)" },
          }}
        />
      </Box>

      {/* 📝 Product Info on the Right */}
      <Box
        sx={{
          width: "60%",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Name & Price */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontFamily: "Roboto, sans-serif",
              color: customStyles?.product_text_color || "#b3a5ff", // Light purple
            }}
          >
            {product.name}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: customStyles?.product_text_color || "#9e88ff",
              fontWeight: 500,
              mt: 0.5,
              fontFamily: "Roboto, sans-serif",
            }}
          >
            {product.price &&
              new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              }).format(product.price)}
          </Typography>
        </Box>

        {/* 📌 Button at Bottom Right */}
        <CardActions sx={{ p: 0, mt: 1 }}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{ width: "100%" }}
          >
            <Button
              variant="contained"
              size="medium"
              onClick={() => onSelect(product)}
              sx={{
                width: "100%",
                backgroundColor: customStyles?.buttons_color || "#7059e0",
                color: "#fff",
                fontWeight: 600,
                fontFamily: "Roboto, sans-serif",
                borderRadius: "12px",
                transition: "all 0.3s ease-in-out",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                "&:hover": {
                  backgroundColor: "#8a71ff",
                  transform: "scale(1.02)",
                },
              }}
            >
              <ShoppingCartIcon sx={{ fontSize: 22 }} />
              Añadir
            </Button>
          </motion.div>
        </CardActions>
      </Box>
    </Card>
  );
};

export const ProductCard = ({ product, onSelect, customStyles }) => {
  return (
    <Card
      component={motion.div}
      initial={{ opacity: 0, scale: 0.95, y: 10 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ duration: 0.2, ease: "easeOut" }}
      whileHover={{ scale: 1.02 }}
      sx={{
        maxWidth: 345,
        backgroundColor: customStyles?.product_background_color || "#26263e",
        borderRadius: "16px",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)",
        transition: "all 0.15s ease-in-out",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column", // 🔥 Permite empujar el botón hacia abajo
        height: "100%", // 🔥 Asegura que todas las tarjetas tengan la misma altura
      }}
    >
      {/* 📷 Imagen con tamaño estándar */}
      <CardMedia
        component="img"
        alt={product.name}
        image={product.image_url ? product.image_url : imagenLocal}
        sx={{
          height: "180px",
          objectFit: "cover",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        }}
      />

      {/* 📜 Contenido con flexGrow */}
      <CardContent sx={{ px: 2, flexGrow: 1 }}>
        {" "}
        {/* 🔥 flexGrow empuja el botón abajo */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontWeight: 500,
              fontFamily: "Roboto, sans-serif",
              color: customStyles?.product_text_color || "white",
            }}
          >
            {product.name}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: customStyles?.product_text_color || "white",
              fontWeight: 500,
              fontFamily: "Roboto, sans-serif",
            }}
          >
            {product.price &&
              new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              }).format(product.price)}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "#7c7c8a",
            mt: 1,
            display: "block",
            textAlign: "justify",
            fontWeight: 400,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          {product.description}
        </Typography>
      </CardContent>

      {/* 📌 Botón Siempre Abajo */}
      <CardActions sx={{ px: 2, pb: 2 }}>
        <Button
          variant="contained"
          size="medium"
          onClick={() => onSelect(product)}
          sx={{
            width: "100%",
            py: 1,
            backgroundColor: customStyles?.buttons_color || "#6c60ab",
            color: "#fff",
            fontWeight: 500,
            fontFamily: "Roboto, sans-serif",
            borderRadius: "12px",
            transition: "all 0.3s ease-in-out",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,

            "&:hover": {
              backgroundColor: "#8474c2",
              transform: "scale(1.02)",
            },
          }}
        >
          <ShoppingCartIcon
            sx={{
              fontSize: 20,
            }}
          />
          Agregar a la Orden
        </Button>
      </CardActions>
    </Card>
  );
};
