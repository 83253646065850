import { GoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function LoginGoogle({ generateTokenEatery }) {
  const [userProfile, setUserProfile] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div>
      {!userProfile ? (
        <>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              generateTokenEatery(credentialResponse.credential);
            }}
            onError={() => {
              setOpenSnackbar(true);
            }}
          />
        </>
      ) : (
        <div>
          <img src={userProfile.picture} alt="Perfil" />
          <p>{userProfile.name}</p>
          <button onClick={() => setUserProfile(null)}>Cerrar sesión</button>
        </div>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          ¡Hubo un error al iniciar sesión con Google! Por favor, inténtalo de nuevo.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default LoginGoogle;