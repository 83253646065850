import React, { useEffect, useState } from "react";
import { makeRequest } from "../assets/functions_helper";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  DialogContentText,
  Snackbar,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { NoSuggestionsTextField } from "../components/textFields";
import { CloseButton, SendButton } from "../components/buttons";
import TextLogo from "../assets/images/text-logo-calls.png";
import LogoLogo from "../assets/images/logo-calls.png";
import LoginGoogle from "./loginGoogle";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../components/customDialog";
import CompletationInfo from "./modules/registration/completationInfo";
import { useForm, FormProvider } from "react-hook-form";
import VerificationCode from "./modules/registration/verificationCode";

function Login({ setIsLoggedIn }) {
  // Variables de estado para guardar los valores de los inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [dataFromGoogle, setDataFromGoogle] = useState(false);
  const [isCodeVerification, setIscodeVerification] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [countdown, setCountdown] = useState(0);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [dataResendCode, setDataResendCode] = useState(false);
  const navigate = useNavigate();
  const methods = useForm();

  useEffect(() => {
    let interval;
    if (isCountdownActive && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsCountdownActive(false); 
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCountdownActive, countdown]);

  const setLocalStarageUserAndNavigate = (response) => {
    localStorage.setItem("token", response.access_token);
    localStorage.setItem("tokenType", response.token_type);
    const userRoles = response.user.roles;
    const userId = response.user.id;

    let minRoleId = 0;
    if (userRoles.length > 0) {
      minRoleId = userRoles.reduce(
        (minId, currentRole) => Math.min(minId, currentRole.id),
        userRoles[0].id
      );
    }

    localStorage.setItem("rol", minRoleId);
    localStorage.setItem("userId", userId);

    setIsLoggedIn(true);
    navigate("/");
  };

  const handleSubmit = (e) => {
    // Validar campos vacios
    if (email === "" || password === "") {
      console.log("Campos vacios");
      return;
    } else {
      // Hacer la peticion si nada esta vacio
      makeRequest(
        "POST",
        "users/login",
        {
          username: email,
          password: password,
        },
        "application/x-www-form-urlencoded",
        null,
        null,
        () => {
          navigate("/login");
        }
      )
        .then((response) => {
          setLocalStarageUserAndNavigate(response);
        })
        .catch((error) => {
          console.error(error);

          if (error.toString().includes("401")) {
            setAlert(true);
            setAlertMessage("Usuario o contraseña incorrectos");
          } else if (error.toString().includes("500")) {
            setAlert(true);
            setAlertMessage("Error en el servidor, intente más tarde");
          } else {
            setAlert(true);
            setAlertMessage("Error desconocido");
          }
        });
    }
  };

  const handleFormSubmit = (data) => {
    if (isCodeVerification) {
      console.log("Verification code:", data);
      onSubmitVerificationCode(data);
    } else {
      setIscodeVerification(true);
      setDataResendCode(data);
      sentRequestVerificationCode(data);
    }
  };

  const sentRequestVerificationCode = (data) => {
    console.log("Datos enviados:", dataFromGoogle);
    console.log("Datos enviados:", data);

    makeRequest(
      "POST",
      "restaurant_admin/send_cellphone_verification",
      {
        cellphone_number: `${data.phoneNumber}`,
        country_id: data.countryId,
      },
      "application/json",
      dataFromGoogle.access_token,
      dataFromGoogle.token_type,
      () => {
        navigate("/login");
      }
    )
      .then(() => {
        console.log("verification code sended");
        setIsCountdownActive(false);
        setCountdown(0);
      })
      .catch((error) => {
        console.log(error);
        if (error.error_code === "0009") {
          const waitTime = error.message.match(
            /Wait for (\d+\.?\d*) seconds/
          )[1];
          const formattedWaitTime = Math.ceil(waitTime);

          setSnackbarMessage(
            `Por favor espera ${formattedWaitTime} segundos antes de volver a generar un nuevo codigo`
          );
          setSnackbarSeverity("warning");
          setCountdown(formattedWaitTime);
          setIsCountdownActive(true);
        } else {
          setSnackbarMessage(`Error al verificar el codigo digitado`);
          setSnackbarSeverity("error");
        }
        setSnackbarOpen(true);
      });
  };

  const sentRequestToUpdatedInfo = (data) => {
    console.log("Datos enviados:", dataFromGoogle);

    makeRequest(
      "POST",
      "restaurant_admin/update_info",
      {
        cellphone_number: `${data.phoneNumber}`,
        country_id: data.countryId,
      },
      "application/json",
      dataFromGoogle.access_token,
      dataFromGoogle.token_type,
      () => {
        navigate("/login");
      }
    )
      .then(() => {
        setLocalStarageUserAndNavigate(dataFromGoogle);
      })
      .catch(() => {
        setSnackbarMessage(`Ocurrio un error al actualizar su informacion`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const onSubmitVerificationCode = (data) => {
    console.log("Verification code:", data.verificationCode);
    makeRequest(
      "POST",
      `restaurant_admin/verify_cellphone_code/${data.verificationCode}`,
      {},
      "application/json",
      dataFromGoogle.access_token,
      dataFromGoogle.token_type,
      () => {
        navigate("/login");
      }
    )
      .then(() => {
        sentRequestToUpdatedInfo(data);
      })
      .catch(() => {
        setSnackbarMessage(`Ocurrio un error al verificar el codigo`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const generateTokenEatery = (token) => {
    console.log(token);
    makeRequest(
      "POST",
      "restaurant_admin/google_authentication",
      token,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log(response);
        setDataFromGoogle(response);
        if (response.needs_registration === true) {
          setOpenMoreInfo(true);
        } else {
          setLocalStarageUserAndNavigate(response);
        }
      })
      .catch((error) => {
        setSnackbarMessage(`Ocurrio un error al intentar autenticarse`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <CustomDialog
        open={openMoreInfo}
        onClose={() => {
          setOpenMoreInfo(!openMoreInfo);
          setIscodeVerification(false);
        }}
      >
        <CustomDialogTitle>Finaliza Registro</CustomDialogTitle>
        <CustomDialogContent>
          <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
            {isCodeVerification ? (
              <>
                Ingresa el código enviado a tu celular
                {countdown > 0 && (
                  <Box mt={1} sx={{ color: "warning.main" }}>
                    Podrás reenviar el código en: {countdown}s
                  </Box>
                )}
              </>
            ) : (
              "Diligencia todos los campos para finalizar tu registro"
            )}
          </DialogContentText>

          {/* 🔹 Pasamos el formulario al contexto */}
          <FormProvider {...methods}>
            {isCodeVerification ? <VerificationCode /> : <CompletationInfo />}
          </FormProvider>
        </CustomDialogContent>

        <CustomDialogActions>
          <CloseButton
            onClick={() => {
              setOpenMoreInfo(!openMoreInfo);
              setIscodeVerification(false);
            }}
          >
            Cerrar
          </CloseButton>
          <SendButton onClick={methods.handleSubmit(handleFormSubmit)}>
            Enviar
          </SendButton>
          {isCodeVerification && (
            <SendButton
              variant="outlined"
              color="secondary"
              disabled={isCountdownActive}
              onClick={() => {
                methods.clearErrors();
                sentRequestVerificationCode(dataResendCode);
              }}
              sx={{
                position: "relative",
                "&:disabled": {
                  borderColor: "action.disabled",
                  color: "text.disabled",
                },
              }}
            >
              {isCountdownActive ? (
                <>
                  Reenviar en {countdown}s
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                </>
              ) : (
                "Reenviar código"
              )}
            </SendButton>
          )}
        </CustomDialogActions>
      </CustomDialog>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            padding: "40px",
            borderRadius: "20px",
            boxShadow: "0px 0px 10px 0px #4EACDA",
            backgroundColor: "#070724",
            backdropFilter: "blur(5px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={LogoLogo}
              alt="Logo"
              style={{ width: "75.79", height: "72.39" }}
            />
            <img
              src={TextLogo}
              alt="Logo"
              style={{ width: "75.79", height: "72.39" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "250px",
            }}
          >
            <NoSuggestionsTextField
              id="outlined-basic"
              label="Email"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />

            <NoSuggestionsTextField
              id="outlined-basic"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              sx={{
                marginBottom: "20px",
              }}
            />

            <SendButton
              onClick={handleSubmit}
              variant="contained"
              sx={{
                backgroundColor: "#4EACDA",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4EACDA",
                },
              }}
              startIcon={<SendIcon />}
            >
              Ingresar
            </SendButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <LoginGoogle generateTokenEatery={generateTokenEatery} />
            </Box>
          </Box>
          <a
            href="/forgotPassword"
            target="_blank"
            style={{
              color: "#4EACDA",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            ¿Olvidaste tu contraseña?
          </a>
          <a
            href="/register"
            target="_blank"
            style={{
              color: "#4EACDA",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            ¿Aun no tienes cuenta? Registrate!!
          </a>
          {alert ? (
            <Alert
              severity="error"
              onClose={() => {
                setAlert(false);
              }}
              sx={{ width: "100%" }}
            >
              <AlertTitle>Error</AlertTitle>
              {alertMessage}
            </Alert>
          ) : null}
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
