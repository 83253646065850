import { useState } from "react";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  IconButton,
  TextField,
  Badge,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { OrderProductCard, ProductCard } from "../../calls/menu_clients/cards";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { motion } from "framer-motion"; // ✅ Import motion for animations

export const EditMenuStyle = ({ menu, onBack }) => {
  const [selectedCard, setSelectedCard] = useState("classic");

  // 🎨 Customization States
  const [customStyles, setCustomStyles] = useState({
    background_color: "#1f1f2e",
    product_background_color: "#26263e",
    product_text_color: "#ffffff",
    buttons_color: "#6c60ab",
    back_button_color: "#6c60ab",
    order_button_color: "#6c60ab",
  });

  const handleChange = (event, newStyle) => {
    if (newStyle) setSelectedCard(newStyle);
  };

  // 🎨 Handle Customization Changes
  const handleStyleChange = (property, value) => {
    setCustomStyles((prev) => ({ ...prev, [property]: value }));
  };

  return (
    <Box
      sx={{
        backgroundColor: customStyles.background_color,
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        gap: 4,
      }}
    >
      {/* 🔥 Left Column - Live Preview */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          maxWidth: "500px",
          gap: 4, // ✅ More spacing between previews
        }}
      >
        {/* 🏷️ Title */}
        <Typography variant="h4" sx={{ color: "white", textAlign: "center" }}>
          Editar Estilo del Menú: {menu.name}
        </Typography>

        {/* 🟣 Animated Card Preview (Now Centered) */}
        <motion.div
          key={selectedCard}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }} // ✅ Centered
        >
          {selectedCard === "classic" ? (
            <ProductCard
              product={{
                name: "Ejemplo Clásico",
                price: 15000,
                description: "Vista previa del estilo clásico.",
                image_url: menu.image,
              }}
              customStyles={customStyles}
            />
          ) : (
            <OrderProductCard
              product={{
                name: "Ejemplo Moderno",
                price: 15000,
                description: "Vista previa del estilo moderno.",
                image_url: menu.image,
              }}
              customStyles={customStyles}
            />
          )}
        </motion.div>

        {/* 🛒 Order & Back Button Previews (Now Aligned & Spaced) */}
        <Box sx={{ display: "flex", gap: 3 }}>
          {" "}
          {/* ✅ Adds spacing between buttons */}
          {/* 🛒 Order Button Preview */}
          <IconButton
            sx={{
              backgroundColor: customStyles.order_button_color,
              color: "#fff",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)",
              "&:hover": { backgroundColor: "#8474c2" },
            }}
          >
            <Badge
              badgeContent={2}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  width: "20px",
                  height: "20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "50%",
                },
              }}
            >
              <ShoppingCartIcon sx={{ fontSize: 28 }} />
            </Badge>
          </IconButton>
          {/* 🔙 Back Button Preview */}
          <IconButton
            onClick={onBack}
            sx={{
              backgroundColor: customStyles.back_button_color,
              color: "white",
              borderRadius: "50%",
              width: "55px",
              height: "55px",
              minWidth: "auto",
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
              "&:hover": { backgroundColor: "#8474c2" },
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>
      </motion.div>

      {/* 🎨 Right Column - Customization Options */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          flex: 1,
          maxWidth: "400px",
          backgroundColor: "#2b2b40",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.4)",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "white", textAlign: "center", mb: 2 }}
        >
          Personalización
        </Typography>

        <ToggleButtonGroup
          value={selectedCard}
          exclusive
          onChange={handleChange}
          sx={{
            borderRadius: "10px",
            "& .MuiToggleButton-root": {
              color: "white",
              border: "none",
              padding: "10px 20px",
              "&.Mui-selected": { backgroundColor: "#6c60ab", color: "white" },
              "&:hover": { backgroundColor: "#8474c2" },
            },
          }}
        >
          <ToggleButton value="classic">Clásico</ToggleButton>
          <ToggleButton value="modern">Moderno</ToggleButton>
        </ToggleButtonGroup>

        <TextField
          label="Color de Fondo"
          type="color"
          value={customStyles.background_color}
          onChange={(e) =>
            handleStyleChange("background_color", e.target.value)
          }
          fullWidth
        />
        <TextField
          label="Color de Tarjeta"
          type="color"
          value={customStyles.product_background_color}
          onChange={(e) =>
            handleStyleChange("product_background_color", e.target.value)
          }
          fullWidth
        />
        <TextField
          label="Color de Texto"
          type="color"
          value={customStyles.product_text_color}
          onChange={(e) =>
            handleStyleChange("product_text_color", e.target.value)
          }
          fullWidth
        />
        <TextField
          label="Color del Botón del Producto"
          type="color"
          value={customStyles.buttons_color}
          onChange={(e) => handleStyleChange("buttons_color", e.target.value)}
          fullWidth
        />
        <TextField
          label="Color del Botón de Regresar"
          type="color"
          value={customStyles.back_button_color}
          onChange={(e) =>
            handleStyleChange("back_button_color", e.target.value)
          }
          fullWidth
        />
        <TextField
          label="Color del Botón de Orden"
          type="color"
          value={customStyles.order_button_color}
          onChange={(e) =>
            handleStyleChange("order_button_color", e.target.value)
          }
          fullWidth
        />

        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#2ECC71",
            color: "white",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "#28B463" },
          }}
        >
          Guardar Estilo
        </Button>
      </Box>
    </Box>
  );
};
