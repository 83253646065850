import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    // Using the requested dark blue color
    backgroundColor: "#070724", // Deep blue-purple background
    color: "#e0e0e0", // Light text for contrast
    padding: theme.spacing(2),
    borderRadius: 16,
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.5)",
    maxWidth: "95%",
    margin: theme.spacing(2),
    overflow: "hidden",
    border: "1px solid #1a1a40", // Subtle border in a slightly lighter shade
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(3, 3, 15, 0.85)", // Much darker blue-tinted backdrop
  },
}));

export const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: 600,
  textAlign: "center",
  padding: theme.spacing(2, 3),
  position: "relative",
  borderBottom: "1px solid #1a1a40", // Slightly lighter blue for divider
  color: "#ffffff", // White text for title
}));

export const DialogCloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: "#8080b0", // Muted blue-purple for the close icon
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  padding: theme.spacing(3),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: "#e0e0e0", // Light gray text for content
}));

export const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(1, 3, 2),
  borderTop: "1px solid #1a1a40", // Slightly lighter blue for divider
}));