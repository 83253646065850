import React from "react";
import { Box } from "@mui/material";
import { NoSuggestionsTextField } from "../../../components/textFields";

function CreateUser({name, email, lastName, password, setName, setEmail, setLastName, setPassword}) {

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}>
      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="name"
        label="Nombre"
        type="text"
        fullWidth
        onChange={(e) => setName(e.target.value)}
        value={name}
      />

      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="lastName"
        name="lastName"
        label="Apellido"
        type="text"
        fullWidth
        onChange={(e) => setLastName(e.target.value)}
        value={lastName}
      />

      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="correo"
        name="email"
        label="Correo"
        type="email"
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />

      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="password"
        name="password"
        label="Contraseña"
        type="password"
        fullWidth
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
    </Box>
  );
}

export default CreateUser;
