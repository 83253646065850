import { useFormContext } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { makeRequest } from "../../../assets/functions_helper";
import { CustomTextField } from "../../../components/textFields";

const CompletationInfo = () => {
  const { register, setValue } = useFormContext();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  useEffect(() => {
    makeRequest(
      "POST",
      "users/countries",
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log(response);
        setCountries(response);
        if (response.length > 0) {
          setSelectedCountry(response[0]);
          setValue("countryId", response[0].id); // Register countryId in form
        }
        setLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage(`Ocurrio un error al completar la informacion`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  }, [setValue]);

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue);
      setValue("countryId", newValue.id);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ maxWidth: 400, mx: "auto", mt: 2 }}>
          <Autocomplete
            options={countries}
            getOptionLabel={(option) => `${option.code} (${option.name})`}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#1E1E1E" : "#f5f5f5",
                  "&&": {
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1E1E1E" : "#f5f5f5",
                  },
                }}
              >
                <img src={option.flag} alt={option.name} width="20" />
                {option.code} ({option.name})
              </Box>
            )}
            value={selectedCountry}
            onChange={handleCountryChange}
            renderInput={(params) => (
              <CustomTextField {...params} label="Select Your Country" />
            )}
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#1E1E1E" : "#f5f5f5",
                  "& .MuiAutocomplete-listbox": {
                    padding: 0,
                    backgroundColor: "transparent",
                  },
                },
              },
            }}
          />
          <input type="hidden" {...register("countryId")} />

          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
            <CustomTextField
              label="Code"
              value={selectedCountry?.code || ""}
              InputProps={{ readOnly: true }}
              sx={{ width: "30%" }}
            />
            <CustomTextField
              label="Phone Number"
              {...register("phoneNumber", { required: "Numero de Telefono" })}
              sx={{ flex: 1 }}
              autoComplete="off" // Set autocomplete off here
              inputProps={{
                autoComplete: "off", // And here
                form: {
                  autoComplete: "off", // In case the form needs it
                },
                "aria-autocomplete": "none",
                role: "presentation",
                spellCheck: "false",
              }}
            />
          </Box>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompletationInfo;
