import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { Select, InputBase } from "@mui/material";

// Enhanced TextField with better contrast and styling
export const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiInputBase-root": {
    background: "rgba(15, 15, 40, 0.4)",
    borderRadius: 12,
    border: "1px solid rgba(90, 78, 153, 0.3)",
    padding: theme.spacing(1, 1.5),
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      border: "1px solid rgba(150, 138, 213, 0.5)",
      background: "rgba(20, 20, 50, 0.5)",
    },
    "&.Mui-focused": {
      border: "1px solid #5A4E99",
      boxShadow: "0 0 0 2px rgba(90, 78, 153, 0.25)",
      background: "rgba(25, 25, 60, 0.6)",
    },
  },
  "& .MuiInputBase-input": {
    color: "#ffffff",
    fontSize: "0.95rem",
    padding: theme.spacing(1, 0.5),
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.5)",
      opacity: 1,
    },
  },
  "& .MuiFormLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
    fontSize: "0.9rem",
    fontWeight: 500,
    transform: "translate(12px, 16px) scale(1)",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#968AD5",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(12px, -6px) scale(0.75)",
    backgroundColor: "#070724",
    padding: "0 5px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

// Enhanced Switch with better visual feedback
export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 34,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 7,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#ffffff",
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(90, 78, 153, 0.8)",
        opacity: 1,
        border: "1px solid rgba(150, 138, 213, 0.7)",
      },
      "& .MuiSwitch-thumb": {
        background: "linear-gradient(92deg, #968AD5 0%, #5A4E99 100%)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    borderRadius: 10,
    background: "#eeeeee",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
    transition: "all 0.2s ease",
  },
  "& .MuiSwitch-track": {
    borderRadius: 17,
    backgroundColor: "rgba(50, 50, 75, 0.8)",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    opacity: 1,
    transition: "background-color 0.2s ease",
  },
}));

// Custom Select with matching style
export const CustomSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  background: "rgba(15, 15, 40, 0.4)",
  borderRadius: 12,
  border: "1px solid rgba(90, 78, 153, 0.3)",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    border: "1px solid rgba(150, 138, 213, 0.5)",
    background: "rgba(20, 20, 50, 0.5)",
  },
  "&.Mui-focused": {
    border: "1px solid #5A4E99",
    boxShadow: "0 0 0 2px rgba(90, 78, 153, 0.25)",
    background: "rgba(25, 25, 60, 0.6)",
  },
  "& .MuiSelect-select": {
    padding: "14px 16px",
    color: "#ffffff",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiMenu-paper": {
    background: "#121232",
    border: "1px solid rgba(90, 78, 153, 0.3)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
  },
  "& .MuiMenuItem-root": {
    color: "#ffffff",
    "&:hover": {
      background: "rgba(90, 78, 153, 0.2)",
    },
    "&.Mui-selected": {
      background: "rgba(90, 78, 153, 0.3)",
      "&:hover": {
        background: "rgba(90, 78, 153, 0.4)",
      },
    },
  },
}));

// Additional styled InputBase for use with Select if needed
export const CustomInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 12,
    position: "relative",
    backgroundColor: "rgba(15, 15, 40, 0.4)",
    border: "1px solid rgba(90, 78, 153, 0.3)",
    fontSize: 16,
    padding: "14px 16px",
    color: "#ffffff",
    transition: "all 0.2s ease-in-out",
    "&:focus": {
      borderRadius: 12,
      borderColor: "#5A4E99",
      boxShadow: "0 0 0 2px rgba(90, 78, 153, 0.25)",
      backgroundColor: "rgba(25, 25, 60, 0.6)",
    },
  },
}));

export const NoSuggestionsTextField = (props) => {
  return (
    <CustomTextField
      {...props}
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      autoComplete="off" // Set autocomplete off here
      inputProps={{
        autoComplete: "off", // And here
        form: {
          autoComplete: "off", // In case the form needs it
        },
        "aria-autocomplete": "none",
        role: "presentation",
        spellCheck: "false",
      }}
    />
  );
};
