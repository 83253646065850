import { Box } from '@mui/material';
import { NoSuggestionsTextField } from '../../../../components/textFields';

function CreateMenu({ name, description, setName, setDescription }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id={`menu-name-${Math.random().toString(36).substr(2, 5)}`}
        name={`menu-name-${Date.now()}`}
        label="Nombre del Menú"
        type="search"
        fullWidth
        onChange={(e) => setName(e.target.value)}
        value={name}
        autoComplete="new-password"
        inputProps={{
          autoComplete: "nope",
          "aria-autocomplete": "none",
          form: "noautocomplete-form",
          role: "presentation",
          spellCheck: "false"
        }}
      />

      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id={`description-name-${Math.random().toString(36).substr(2, 5)}`}
        name={`description-name-${Date.now()}`}
        label="Descripción del Menú"
        type="search"
        fullWidth
        onChange={(e) => setDescription(e.target.value)}
        value={description}
        autoComplete="new-password"
        inputProps={{
          autoComplete: "nope",
          "aria-autocomplete": "none",
          form: "noautocomplete-form",
          role: "presentation",
          spellCheck: "false"
        }}
      />
    </Box>
  );
}

export default CreateMenu;
