import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import { useEffect, useState } from "react";
import "./App.css"; // Importa los estilos CSS globales
import NotifyButton from "./pages/modules/calls/call";
import QRCodeGenerator from "./pages/modules/calls/qr_generator";
import { makeRequest } from "./assets/functions_helper";
import { Box, CircularProgress } from "@mui/material";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import TawkToWidget from "./components/tawlkto";
import MenuClients from "./pages/modules/calls/menu_clients/menu_clients";
import Register from "./pages/modules/registration/register";


function RoutesComponent({ isLoggedIn, setIsLoggedIn }) {
  return (
    <Routes>
      <Route path="/clientes/:tableNumber" element={<NotifyButton />} />
      <Route path="/restaurantMenu/:tableNumber" element={<MenuClients />} />
      <Route path="/qr_generate/:tableNumber" element={<QRCodeGenerator />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/reset_password/:token" element={<ResetPassword />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/login"
        element={
          isLoggedIn ? (
            <Navigate to="/" />
          ) : (
            <Login setIsLoggedIn={setIsLoggedIn} />
            
          )
        }
      />
      <Route
        path="/"
        element={
          isLoggedIn ? (
            <Dashboard setIsLoggedIn={setIsLoggedIn} />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    </Routes>
  );
}

function App() {
  const [cargando, setCargando] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    makeRequest(
      "GET",
      "users/validate_auth",
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setIsLoggedIn(true);
        setCargando(false);
      })
      .catch((error) => {
        console.log(error);
        console.log("Error al obtener los usuarios");
        setCargando(false);
      });
  }, [setIsLoggedIn]);

  return (
    <>
      {cargando ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Router>
          <RoutesComponent
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
          />
          <TawkToWidget/>
        </Router>
      )}
    </>
  );
}

export default App;
