import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { NoSuggestionsTextField } from "../../../../components/textFields";
import { SendButton } from "../../../../components/buttons";

function CreateProduct({
  name,
  setName,
  category,
  setCategory,
  price,
  setPrice,
  description,
  setDescription,
  setImage,
}) {
  const [displayPrice, setDisplayPrice] = useState("");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePriceChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    setPrice(inputValue);

    const formattedPrice = `$${parseInt(inputValue || 0).toLocaleString("es-CL")}`;
    setDisplayPrice(formattedPrice); // Actualizar solo la visualización
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <NoSuggestionsTextField
        autoFocus
        required
        margin="dense"
        id="name"
        label="Nombre del Producto"
        type="text"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <NoSuggestionsTextField
        required
        margin="dense"
        id="category"
        label="Categoría"
        type="text"
        fullWidth
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />

      <NoSuggestionsTextField
        required
        margin="dense"
        id="description"
        label="Descripción"
        type="text"
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <NoSuggestionsTextField
        required
        margin="dense"
        id="price"
        label="Precio"
        type="text"
        fullWidth
        value={displayPrice} 
        onChange={handlePriceChange}
      />

      <Box sx={{ marginTop: "16px" }}>
        <input
          id="product_image"
          name="product_image"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        <label htmlFor="product_image">
          <SendButton
            component="span"
            sx={{
              backgroundColor: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#115293",
              },
              width: "100%",
              padding: "12px",
            }}
          >
            Subir Imagen
          </SendButton>
        </label>
        <Typography
          variant="caption"
          display="block"
          sx={{ marginTop: "8px", color: "#6b6b6b" }}
        >
          Seleccione una imagen para el producto (PNG, JPG, JPEG)
        </Typography>
      </Box>
    </Box>
  );
}

export default CreateProduct;
