import { useState, useEffect, useRef } from "react";
import { InfoIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { errorMapping, makeRequest } from "../../../../assets/functions_helper";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  Badge,
  Container,
  useMediaQuery,
  Drawer,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  AppBar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import orderIcon from "../../../../assets/images/order.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import imagenLocal from "../../../../assets/images/sections.jpg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import CloseIcon from "@mui/icons-material/Close";
import { ProductCard } from "./cards";


const Header = ({ isVisible, handleBack }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : -50 }}
      transition={{ duration: 0.5 }}
      style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}
    >
      <AppBar
        position="static"
        sx={{
          height: "90px", // ⬆️ Taller Header
          backgroundImage:
            "url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/wallpaperflare.com_wallpaper.jpg-PA4b0ZzXGWnybVmdhYtUbhsfKrwzaY.jpeg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxShadow: "none",
          display: "flex",
          alignItems: "center",
          paddingX: "20px",
          position: "relative",
        }}
      >
        {/* Back Button: Positioned at the Start (Top-Left) & Centered in Y */}
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          style={{
            position: "absolute",
            top: "50%", // Centers vertically
            left: "20px", // Stays at the left edge
            transform: "translateY(-50%)", // Perfect vertical centering
          }}
        >
          <Button
            onClick={handleBack}
            sx={{
              backgroundColor: "#6c60ab",
              color: "white",
              borderRadius: "50%",
              width: "55px",
              height: "55px",
              minWidth: "auto",
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
              "&:hover": {
                backgroundColor: "#8474c2",
              },
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </Button>
        </motion.div>
      </AppBar>
    </motion.div>
  );
};



const renderProductSection = (title, products, index, onSelect) => (
  <Box key={index} className="mb-8 text-center relative">
    <Box
      className="relative mx-auto overflow-hidden rounded-xl"
      sx={{
        minHeight: "170px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "1200px", // 🔥 Evita desbordes en pantallas grandes
        width: "100%",
      }}
    >
      {/* Imagen de fondo con opacidad más baja */}
      <img
        src={imagenLocal}
        alt="Fondo de sección"
        className="absolute w-full h-full object-cover opacity-60"
        style={{ maxWidth: "100%" }} // 🔥 Evita que la imagen crezca más de la cuenta
      />

      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "white",
          position: "relative",
          zIndex: 1,
          px: 4,
          py: 2,
        }}
      >
        {title}
      </Typography>
    </Box>

    {/* Contenedor de Tarjetas con corrección de alineación */}
    <Grid
      container
      spacing={4}
      sx={{
        mt: 4,
        justifyContent: "center", // 🔥 Asegura que todas las tarjetas estén centradas
        maxWidth: "1200px",
      }}
    >
      {products
        .sort((a, b) => a.order - b.order)
        .map((product) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            key={product.id}
            sx={{
              display: "flex",
              justifyContent: "center", // 🔥 Centra cada tarjeta
            }}
          >
            <ProductCard product={product} onSelect={onSelect} />
          </Grid>
        ))}
    </Grid>
  </Box>
);

const DialogSelectedItem = ({
  open,
  onClose,
  selectedProduct,
  addToCart,
  quantity,
  setQuantity,
}) => {
  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => quantity > 1 && setQuantity(quantity - 1);

  const totalPrice = selectedProduct?.price
    ? selectedProduct.price * quantity
    : 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        borderRadius: "16px",
        "& .MuiDialog-paper": {
          borderRadius: "16px",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.4)",
        },
      }}
      component={motion.div}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.2, ease: "easeOut" }}
    >
      <DialogContent
        sx={{
          p: 0,
          backgroundColor: "#26263e",
          borderRadius: "16px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // 📱 En móviles, en columna
          height: "100%",
          minHeight: "300px",
        }}
      >
        <Grid container sx={{ flex: 1 }}>
          {/* 📱 Mobile: Imagen arriba / 💻 Desktop: Imagen a la izquierda */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: { xs: "200px", md: "100%" },
              overflow: "hidden",
              borderRadius: { xs: "16px 16px 0 0", md: "16px 0 0 16px" },
            }}
          >
            <motion.img
              src={selectedProduct?.image_url || imagenLocal}
              alt={selectedProduct?.name || "Producto"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              initial={{ scale: 1.1 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            />
          </Grid>

          {/* 📱 Mobile: Contenido abajo / 💻 Desktop: Contenido a la derecha */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 2,
              textAlign: { xs: "center", md: "left" }, // 📱 Centramos el texto en mobile
            }}
          >
            <Box>
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, color: "#9d95cc" }}
              >
                {selectedProduct?.name}
              </Typography>

              <Typography
                variant="body1"
                sx={{ color: "#8474c2", fontWeight: 500, mt: 1 }}
              >
                Precio:{" "}
                {new Intl.NumberFormat("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                }).format(totalPrice)}
              </Typography>

              {/* Descripción del producto */}
              <Typography
                variant="body2"
                sx={{ color: "#b3b3b3", fontSize: "0.9rem", mt: 1 }}
              >
                {selectedProduct?.description}
              </Typography>
            </Box>

            {/* 📱 Mobile: Selector y botón en columna / 💻 Desktop: En fila */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Selector de cantidad */}
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "#3c3c52",
                  borderRadius: "12px",
                  p: 1,
                  boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.2)",
                }}
              >
                <IconButton onClick={handleDecrease} sx={{ color: "white" }}>
                  <RemoveIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  sx={{ mx: 2, color: "white", fontWeight: 500 }}
                >
                  {quantity}
                </Typography>
                <IconButton onClick={handleIncrease} sx={{ color: "white" }}>
                  <AddIcon />
                </IconButton>
              </Box>

              {/* Botón con Precio */}
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ width: "100%" }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    addToCart({ ...selectedProduct, quantity });
                    onClose();
                  }}
                  sx={{
                    backgroundColor: "#6c60ab",
                    color: "#fff",
                    fontWeight: 500,
                    fontFamily: "Roboto, sans-serif",
                    borderRadius: "12px",
                    py: 1.5,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 3,
                    "&:hover": {
                      backgroundColor: "#8474c2",
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <SendIcon sx={{ mr: 1 }} />
                    Añadir A la Orden
                  </Box>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    {new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                      minimumFractionDigits: 0,
                    }).format(totalPrice)}
                  </Typography>
                </Button>
              </motion.div>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const CartButton = ({ cart, setIsCartOpen, showAddedAnimation }) => {
  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);
  const [showMessage, setShowMessage] = useState(false);

  // Mostrar el mensaje solo cuando `showAddedAnimation` es true
  useEffect(() => {
    if (showAddedAnimation) {
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 2000); // 🔥 Se oculta después de 2 segundos
    }
  }, [showAddedAnimation]);

  return (
    <div
      className="fixed top-5 right-5 flex flex-col items-end"
      style={{ zIndex: 1000 }}
    >
      {/* Mensaje de "Producto Agregado" con animación */}
      <AnimatePresence>
        {showMessage && (
          <motion.div
            initial={{ opacity: 0, y: -20, scale: 0.9, filter: "blur(5px)" }}
            animate={{ opacity: 1, y: 0, scale: 1, filter: "blur(0px)" }}
            exit={{ opacity: 0, y: -20, scale: 0.9, filter: "blur(5px)" }}
            transition={{ duration: 0.3 }}
            className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md mb-2"
          >
            Producto Agregado!
          </motion.div>
        )}
      </AnimatePresence>

      {/* Botón del carrito */}
      <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
        <IconButton
          onClick={() => setIsCartOpen(true)}
          sx={{
            backgroundColor: "#6c60ab",
            color: "#fff",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)",
            "&:hover": {
              backgroundColor: "#8474c2",
            },
          }}
        >
          <Badge
            badgeContent={totalItems > 0 ? totalItems : null}
            color="error"
            sx={{
              "& .MuiBadge-badge": {
                width: "20px",
                height: "20px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "50%",
              },
            }}
          >
            <ShoppingCartIcon sx={{ fontSize: 28 }} />
          </Badge>
        </IconButton>
      </motion.div>
    </div>
  );
};

const HeroSection = ({ handleBack, setHeaderVisible }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > window.innerHeight * 0.8) { // ⬇️ Hero fades out later
        setIsVisible(false);
        setHeaderVisible(true);
      } else {
        setIsVisible(true);
        setHeaderVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeaderVisible]);

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: isVisible ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        className="relative h-screen w-full"
        sx={{
          backgroundImage:
            "url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/wallpaperflare.com_wallpaper.jpg-PA4b0ZzXGWnybVmdhYtUbhsfKrwzaY.jpeg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          position: "relative",
        }}
      >
        {/* Overlay */}
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.2))",
          }}
        />

        {/* Back Button */}
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <Button
            onClick={handleBack}
            sx={{
              position: "fixed",
              top: "20px",
              left: "20px",
              zIndex: 50,
              backgroundColor: "#6c60ab",
              color: "white",
              borderRadius: "50%",
              width: "55px",
              height: "55px",
              minWidth: "auto",
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
              "&:hover": {
                backgroundColor: "#8474c2",
              },
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </Button>
        </motion.div>

        {/* Main Content */}
        <Box className="relative z-10 flex flex-col items-center justify-center h-full text-center px-4">
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="mb-4 rounded-full bg-primary/30 p-4"
          >
            <RestaurantMenuIcon sx={{ fontSize: 50, color: "#fff" }} />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <Typography variant="h2" sx={{ fontWeight: "bold", color: "white" }}>
              Menú Exclusivo
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <Typography
              variant="h6"
              sx={{ color: "#e0e0e0", maxWidth: "500px", mt: 2 }}
            >
              Explora nuestra selección de platillos gourmet y realiza tu pedido
              con un solo clic.
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: -10 }}
            transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
            className="absolute bottom-10"
          >
            <ExpandMoreIcon sx={{ fontSize: 50, color: "white" }} />
          </motion.div>
        </Box>
      </Box>
    </motion.div>
  );
};

const ProductSections = ({
  menu,
  handleSelectProduct,
  renderProductSection,
}) => {
  return (
    <Box sx={{ backgroundColor: "#1c1c34", py: 8 }}>
      <Container maxWidth="lg">
        {menu.sections.map((section, index) => (
          <Box key={index} sx={{ mb: 6 }}>
            {/* Contenedor de Tarjetas */}

            {renderProductSection(
              section.name,
              section.products,
              index,
              handleSelectProduct
            )}
          </Box>
        ))}
      </Container>
    </Box>
  );
};

const ResponsiveProductSections = ({
  menu,
  handleSelectProduct,
  renderProductSection,
}) => {
  const isMobile = useMediaQuery("(max-width:550px)"); // 📱 Detecta si es menor a 1024px (Tablets y móviles)

  return (
    <>
      {/* 💻 Solo en Desktop */}
      {!isMobile && (
        <div className="bg-[#1c1c34]">
          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
            {menu.sections.map((section, index) =>
              renderProductSection(
                section.name,
                section.products,
                index,
                handleSelectProduct
              )
            )}
          </div>
        </div>
      )}

      {/* 📱 Solo en Móviles */}
      {isMobile && (
        <ProductSections
          menu={menu}
          handleSelectProduct={handleSelectProduct}
          renderProductSection={renderProductSection}
        />
      )}
    </>
  );
};

const CartSidebar = ({
  isCartOpen,
  setIsCartOpen,
  cart,
  decreaseQuantity,
  increaseQuantity,
  callWaiter,
}) => {
  const totalPrice = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <Drawer
      anchor="right"
      open={isCartOpen}
      onClose={() => setIsCartOpen(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "100%", sm: "420px" },
          backgroundColor: "#26263e",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.4)",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/* 🔹 Header con Icono y Botón de Cerrar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 3,
        }}
      >
        <img src={orderIcon} alt="Orden" style={{ width: 50, height: 50 }} />
        <IconButton
          onClick={() => setIsCartOpen(false)}
          sx={{
            color: "white",
            backgroundColor: "#ff4d4d", // 🔥 Rojo más llamativo
            "&:hover": { backgroundColor: "#ff6666" },
            boxShadow: "0px 4px 10px rgba(255, 77, 77, 0.4)", // 🔥 Sombra ligera
          }}
        >
          <CloseIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Box>

      {/* 🔹 Contenedor Scrollable */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          px: 3,
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {cart.length === 0 ? (
          <Typography
            variant="h6"
            align="center"
            sx={{ color: "white", my: 4 }}
          >
            Tu orden está vacía.
          </Typography>
        ) : (
          cart.map((item) => (
            <Grid
              key={item.id}
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                borderBottom: "1px solid #444",
                pb: 2,
                mb: 2,
              }}
            >
              {/* Nombre del Producto */}
              <Grid item xs={12} sm={5} textAlign="center">
                <Typography
                  variant="h6"
                  sx={{ color: "white", fontWeight: 600 }}
                >
                  {item.name}
                </Typography>
              </Grid>

              {/* Controles de Cantidad */}
              <Grid item xs={12} sm={4} display="flex" justifyContent="center">
                <IconButton
                  onClick={() => decreaseQuantity(item.product_id)}
                  sx={{
                    color: "white",
                    backgroundColor: "#ff9900", // 🔥 Naranja atractivo
                    "&:hover": { backgroundColor: "#ffb84d" },
                    boxShadow: "0px 4px 10px rgba(255, 153, 0, 0.4)",
                  }}
                >
                  <RemoveIcon />
                </IconButton>

                <Typography
                  variant="h6"
                  sx={{ mx: 2, color: "white", fontWeight: "bold" }}
                >
                  x{item.quantity}
                </Typography>

                <IconButton
                  onClick={() => increaseQuantity(item.product_id)}
                  sx={{
                    color: "white",
                    backgroundColor: "#00cc66", // 🔥 Verde llamativo
                    "&:hover": { backgroundColor: "#00e673" },
                    boxShadow: "0px 4px 10px rgba(0, 204, 102, 0.4)",
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Grid>

              {/* Precio */}
              <Grid item xs={12} sm={3} textAlign="center">
                <Typography
                  variant="h6"
                  sx={{ color: "#9d95cc", fontWeight: 600 }}
                >
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                    minimumFractionDigits: 0,
                  }).format(item.price * item.quantity)}
                </Typography>
              </Grid>
            </Grid>
          ))
        )}
      </Box>

      {/* 🔹 Contenedor del Total y Botón */}
      {cart.length > 0 && (
        <Box
          sx={{
            p: 3,
            borderTop: "1px solid #444",
            textAlign: "center",
            background: "linear-gradient(45deg, #6c60ab, #8474c2)", // 🔥 Fondo con degradado
            boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.3)", // 🔥 Sombra superior
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: "white", fontWeight: "bold", mb: 2 }}
          >
            Total:{" "}
            {new Intl.NumberFormat("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            }).format(totalPrice)}
          </Typography>

          <Button
            fullWidth
            variant="contained"
            onClick={callWaiter}
            sx={{
              backgroundColor: "#ff4d4d", // 🔥 Rojo llamativo
              color: "#fff",
              fontWeight: 500,
              fontSize: "1.1rem",
              borderRadius: "12px",
              py: 1.5,
              transition: "all 0.3s ease-in-out",
              boxShadow: "0px 6px 15px rgba(255, 77, 77, 0.4)", // 🔥 Sombra en el botón
              "&:hover": {
                backgroundColor: "#ff6666",
                transform: "scale(1.02)",
              },
            }}
          >
            <SendIcon sx={{ mr: 1, fontSize: 28 }} />
            Realizar Orden
          </Button>
        </Box>
      )}
    </Drawer>
  );
};

const OrderReceivedSnackbar = ({ open, setOpen }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} // 🔥 Se cierra automáticamente después de 3 segundos
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "center", horizontal: "center" }} // 🔥 Siempre centrado
    >
      <Alert
        onClose={() => setOpen(false)}
        severity="success"
        variant="filled"
        sx={{
          backgroundColor: "#22c55e", // Verde elegante
          color: "white",
          fontSize: "1.2rem",
          fontWeight: "bold",
          px: 3,
          py: 2,
          borderRadius: "12px",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.3)", // 🔥 Sombra premium
        }}
      >
        ✅ Orden Recibida! Un mesero estará contigo muy pronto.
      </Alert>
    </Snackbar>
  );
};

const SectionFilter = ({ menu, setFilteredSections }) => {
  const [selectedSection, setSelectedSection] = useState("Todos");
  const sectionRef = useRef(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedSection(value);

    if (value === "Todos") {
      setFilteredSections(menu.sections);
    } else {
      const filtered = menu.sections.filter(
        (section) => section.name === value
      );
      setFilteredSections(filtered);
    }

    // 🔥 Mueve el scroll a la sección filtrada
    setTimeout(() => {
      sectionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100); // Pequeño delay para que el filtro se actualice antes de hacer scroll
  };

  return (
    <>
      {/* 🔥 Select con animación */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "92%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControl
            sx={{
              width: "100%",
              maxWidth: { xs: "90%", sm: "450px", md: "500px" },
              backgroundColor: "#6c60ab",
              borderRadius: "12px",
              boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.3)",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                boxShadow: "0px 12px 30px rgba(0, 0, 0, 0.5)",
                backgroundColor: "#8474c2",
              },
            }}
          >
            <InputLabel
              sx={{ color: "white", fontWeight: 600, fontSize: "1rem" }}
            >
              Selecciona una Sección
            </InputLabel>
            <Select
              value={selectedSection}
              onChange={handleChange}
              IconComponent={ExpandMoreIcon}
              MenuProps={{
                PaperProps: {
                  component: motion.div,
                  initial: { opacity: 0, y: -10 },
                  animate: { opacity: 1, y: 0 },
                  transition: { duration: 0.3, ease: "easeOut" },
                  sx: {
                    backgroundColor: "#3c3c52",
                    color: "white",
                    borderRadius: "12px",
                  },
                },
              }}
              sx={{
                color: "white",
                fontWeight: 500,
                borderRadius: "12px",
                py: 1,
                "& .MuiSvgIcon-root": { color: "white" },
                "&:focus": { backgroundColor: "#9d95cc" },
              }}
            >
              <MenuItem
                value="Todos"
                sx={{
                  "&:hover": { backgroundColor: "#6c60ab" },
                }}
              >
                🍽️ Todos
              </MenuItem>

              {menu.sections.map((section) => (
                <MenuItem
                  key={section.name}
                  value={section.name}
                  sx={{
                    "&:hover": { backgroundColor: "#6c60ab" },
                  }}
                >
                  {section.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </motion.div>

      {/* 🔥 Elemento de referencia para hacer scroll */}
      <div ref={sectionRef} style={{ marginTop: "100px" }} />
    </>
  );
};

const MenuWithFilter = ({
  menu,
  handleSelectProduct,
  renderProductSection,
  handleBack,
}) => {
  const [filteredSections, setFilteredSections] = useState(menu.sections);
  const [headerVisible, setHeaderVisible] = useState(false);

  return (
    <>
      {/* 🔥 Contenedor Relativo para que SectionFilter flote sin dejar espacio en blanco */}
      <Box sx={{ position: "relative", pb: 8, background: "#1c1c34" }}>
        <Header isVisible={headerVisible} handleBack={handleBack} />
        <HeroSection handleBack={handleBack} setHeaderVisible={setHeaderVisible} />
        <SectionFilter menu={menu} setFilteredSections={setFilteredSections} />
      </Box>

      {/* 🔥 Secciones filtradas (subimos para eliminar el espacio en blanco) */}
      <ResponsiveProductSections
        menu={{ ...menu, sections: filteredSections }}
        handleSelectProduct={handleSelectProduct}
        renderProductSection={renderProductSection}
      />
    </>
  );
};

export default function MenuClients() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDetailProduct, setOpenDetailProduct] = useState(false);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [showAddedAnimation, setShowAddedAnimation] = useState(false);
  const [showOrderReceivedAnimation, setShowOrderReceivedAnimation] =
    useState(false);
  const [showErrorAnimation, setShowErrorAnimation] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const [notAMenu, setNotAMenu] = useState(false);
  const [quantity, setQuantity] = useState(1);

  let { tableNumber } = useParams();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navega a la página anterior
  };

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (item) => item.product_id === product.product_id
      );
      if (existingItem) {
        return prevCart.map((item) =>
          item.product_id === product.product_id
            ? { ...item, quantity: item.quantity + product.quantity }
            : item
        );
      }
      return [...prevCart, { ...product, quantity: product.quantity }];
    });
    console.log(product);
    console.log(cart);

    setShowAddedAnimation(true);
    setSelectedProduct(null);
  };

  const callWaiter = () => {
    let obj = {
      table_public_id: tableNumber ? tableNumber : "3test",
      code: localStorage.getItem("tableCode"),
      comment: "",
      latitude: 12,
      longitude: 12,
      call_type: "order_call",
      order: {
        products: cart,
        total_price: cart
          .reduce(
            (total, item) => total + parseFloat(item.price) * item.quantity,
            0
          )
          .toFixed(2),
      },
    };
    makeRequest(
      "POST",
      `notify_waiters/${tableNumber ? tableNumber : "3test"}`,
      obj,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then(() => {
        setShowOrderReceivedAnimation(true);
        setCart([]);
      })
      .catch((error) => {
        const segundosRestantes = error.value;
        for (const err in errorMapping) {
          if (err.includes(error["error_code"])) {
            setMessageError(
              `⏳ Debes esperar ${segundosRestantes} ` + errorMapping[err]
            );
            setShowErrorAnimation(true);
            return;
          }
        }
      });
    console.log(obj);
    setIsCartOpen(false);
  };

  useEffect(() => {
    if (showAddedAnimation) {
      const timer = setTimeout(() => {
        setShowAddedAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAddedAnimation]);

  useEffect(() => {
    if (showOrderReceivedAnimation) {
      const timer = setTimeout(() => {
        setShowOrderReceivedAnimation(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showOrderReceivedAnimation]);

  useEffect(() => {
    if (showErrorAnimation) {
      const timer = setTimeout(() => {
        setShowErrorAnimation(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showErrorAnimation]);

  useEffect(() => {
    makeRequest(
      "POST",
      `menu_manager/public_menu?table_id=${
        tableNumber ? tableNumber : "3test"
      }`,
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        const validSections = response.menu.sections
          ? [...response.menu.sections]
          : [];
        validSections.sort((a, b) => (a.order || 0) - (b.order || 0));

        setMenu({ ...response, sections: validSections });
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error al obtener los menús:", error);
        setLoading(false);
        setNotAMenu(true);
      });
  }, [tableNumber]);

  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.product_id === productId
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.product_id === productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    setOpenDetailProduct(true);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : notAMenu ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Ocupa toda la altura visible
            width: "100%",
            backgroundColor: "background.default",
            flexDirection: "column",
            gap: 2,
            textAlign: "center",
            p: 3,
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{
              fontWeight: 500,
              maxWidth: 600,
              lineHeight: 1.5,
            }}
          >
            No hay menús registrados
          </Typography>

          {/* Opcional: Agregar un icono */}
          <InfoIcon
            sx={{
              fontSize: 64,
              color: "text.disabled",
              opacity: 0.6,
            }}
          />
        </Box>
      ) : (
        <>
          <div className="min-h-screen bg-gray-900 text-white">
            <MenuWithFilter
              menu={menu}
              handleSelectProduct={handleSelectProduct}
              renderProductSection={renderProductSection}
              handleBack={handleBack}
            />

            <DialogSelectedItem
              open={openDetailProduct}
              onClose={() => setOpenDetailProduct(!openDetailProduct)}
              selectedProduct={selectedProduct}
              addToCart={addToCart}
              quantity={quantity}
              setQuantity={setQuantity}
            />

            <CartButton
              cart={cart}
              setIsCartOpen={setIsCartOpen}
              showAddedAnimation={showAddedAnimation}
            />

            {/* Cart Modal */}

            <CartSidebar
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              cart={cart}
              decreaseQuantity={decreaseQuantity}
              increaseQuantity={increaseQuantity}
              callWaiter={callWaiter}
            />

            <AnimatePresence>
              {showErrorAnimation && (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white p-6 rounded-lg shadow-lg text-center"
                >
                  <p className="text-xl font-bold mb-2">Error!</p>
                  <p>{messageError}</p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <OrderReceivedSnackbar
            open={showOrderReceivedAnimation}
            setOpen={setShowOrderReceivedAnimation}
          />
        </>
      )}
    </>
  );
}
